import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet-async"

const AboutPage = () => {
  return (
    <>
      <Helmet title="About - JiggSwap" />
      <div className="site-content">
        <div className="has-text-centered">
          <div className="subtitle" style={{ marginTop: "3rem" }}>
            Hi, I'm Andrew!
          </div>

          <div>
            <img src="https://jiggswap.s3.us-east-2.amazonaws.com/assets/me.png" alt="Andrew" style={{ height: "400px" }} />
          </div>
        </div>
        <div className="content has-text-centered" style={{ marginTop: "2rem" }}>
          <p>
            I enjoy doing puzzles as a way to spend time with loved ones, but doing the same puzzles over and over can get a bit stale.
            Throwing puzzles out seemed wasteful, so I started trading with family and friends.
          </p>
          <p>
            I made Jiggswap to help puzzle lovers like you get puzzles you haven't done, and save money at the same time! This way, you'll
            always have a fresh puzzle to do, and you'll help keep re-usable items out of landfills. And, Jiggswap can also help you keep
            track of the puzzles you've done.{" "}
          </p>
          <p>
            My team is constantly working to improve Jiggswap. <Link to="/coming-soon">Here's a sneak peak</Link> of what we're planning to
            bring to Jiggswap soon.{" "}
          </p>
          <p>
            I'd love to hear your thoughts and ideas about Jiggswap, or about puzzles in general. You're welcome to send questions,
            comments, and suggestions to me over at the <Link to="/contact">Contact</Link> page or by directly emailing me at{" "}
            <a href="mailto:andrew@jiggswap.com">andrew@jiggswap.com.</a>
          </p>
          <p></p>
        </div>
      </div>
    </>
  )
}
export default AboutPage
